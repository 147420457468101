import * as lucideIcons from "lucide-react";
import { twMerge } from "tailwind-merge";

function Lucide(props) {
  const { icon, className, ...computedProps } = props;
  const Component = lucideIcons[icon]; // Acesse o ícone diretamente do namespace
  if (!Component) {
    console.error(`Icon "${icon}" does not exist in lucide-react`);
    return null; // Retorne null ou outro fallback se o ícone não existir
  }
  return (
    <Component
      {...computedProps}
      className={twMerge(["stroke-1.5 w-5 h-5", className])}
    />
  );
}

export default Lucide;
