import { useEffect } from "react";
import { toast } from "react-toastify";

function FormikEffect({ formik, onSubmissionError, message }) {
  useEffect(() => {
    if (formik.isValid || formik.submitCount <= 0) return;

    if (onSubmissionError) {
      onSubmissionError();
      return;
    }

    toast.info(message || 'Erro de validação! Por favor, confira os campos do formulário.');
  }, [formik.submitCount, formik.isValid, onSubmissionError, message]);

  return null;
}

export { FormikEffect };
