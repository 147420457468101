import { useState } from "react";
import { Modal as ModalAntd } from "antd";
import Button from "../Button";
import Lucide from "../Lucide";

function HeaderModal({ title, closeModal }) {
  return (
    <div className="flex justify-between items-center">
      <h6 className="text-black text-[20px] font-semibold leading-7">
        {title}
      </h6>
      <button onClick={closeModal}>
        <Lucide
          icon="X"
          className="hover:text-danger transiton duration-300 hover:scale-125 text-gray-600"
        />
      </button>
    </div>
  );
}

function FooterModal({ closeModal }) {
  return (
    <div>
      <Button
        type="button"
        className="mr-2 border-0 shadow-none text-gray-600 focus:ring-0 text-[14px] font-semibold leading-3 hover:text-danger/70"
        onClick={closeModal}
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        form="customerForm"
        variant="primary"
        className="w-[124px] h-[32px] text-[13px] font-semibold leading-4"
      >
        Adicionar cliente
      </Button>
    </div>
  );
}

function Modal({ title, open, closeModal, footer, children, isLoading, width }) {
  return (
    <ModalAntd
      zIndex={99}
      title={<HeaderModal title={title} closeModal={closeModal} />}
      closeIcon={false}
      confirmLoading={true}
      width={width}
      styles={{
        content: { padding: "10px" },
        header: {
          padding: "5px 15px 15px 15px",
          borderBottom: "1px dashed #E0E0E0",
          margin: 0,
        },
        body: { padding: "20px 15px" },
        footer: {
          padding: "20px 15px 10px 15px",
          marginTop: "0px",
          borderTop: "1px dashed #E0E0E0",
        },
      }}
      centered
      footer={footer}
      open={open}
      onCancel={closeModal}
    >
      {isLoading ? <p>Carregando...</p> : children}
    </ModalAntd>
  );
}

export default Modal;
