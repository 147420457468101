import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import CustomersList from './pages/Customers/CustomersList';
import OrdersList from './pages/Orders/OrdersList';
import InvoicesList from './pages/Invoices/InvoicesList';
import ProductsList from './pages/Products/ProductsList';
import TicketsList from './pages/Tickets/TicketsList';
import LogsList from './pages/Logs/LogsList';
import Base from "./layout/Base";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />

        <Route element={<Base />}>
          <Route path="/customers" element={<CustomersList />} />
          <Route path="/orders" element={<OrdersList />} />
          <Route path="/invoices" element={<InvoicesList />} />
          <Route path="/products" element={<ProductsList />} />
          <Route path="/tickets" element={<TicketsList />} />
          <Route path="/logs" element={<LogsList />} />
          </Route>
      </Routes>
    </Router>
  );
}

export default App;
