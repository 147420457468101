class TokenService {
  static getAccessToken() {
    return localStorage.getItem("access_token");
  }

  static setAccessToken(access_token) {
    localStorage.setItem("access_token", access_token);
  }

  static removeAccessToken() {
    localStorage.removeItem("access_token");
  }
}

export default TokenService;
