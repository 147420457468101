const validateCPF = (value) => {
    if (!value) return false;
  
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, '');
  
    if (value.length !== 11 || /^(\d)\1{10}$/.test(value)) return false; // Verifica se tem 11 dígitos e se não são todos iguais
  
    const calcCheckDigit = (cpf, length) => {
      let sum = 0;
      for (let i = 0; i < length; i++) {
        sum += parseInt(cpf[i]) * (length + 1 - i);
      }
      const remainder = (sum * 10) % 11;
      return remainder === 10 ? 0 : remainder;
    };
  
    const checkDigit1 = calcCheckDigit(value, 9);
    const checkDigit2 = calcCheckDigit(value, 10);
  
    return checkDigit1 === parseInt(value[9]) && checkDigit2 === parseInt(value[10]);
  };
  
  const validateCNPJ = (cnpj) => {
    if (!cnpj) return false;
  
    // Remove caracteres não numéricos
    const cleanedCNPJ = cnpj.replace(/\D/g, '');
  
    if (cleanedCNPJ.length !== 14 || /^(\d)\1{13}$/.test(cleanedCNPJ)) return false; // Verifica se tem 14 dígitos e se não são todos iguais
  
    const calcCheckDigit = (cnpj, length) => {
      const weights = length === 12 ? [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2] : [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      let sum = 0;
  
      for (let i = 0; i < length; i++) {
        sum += parseInt(cnpj[i]) * weights[i];
      }
  
      const remainder = sum % 11;
      return remainder < 2 ? 0 : 11 - remainder;
    };
  
    const checkDigit1 = calcCheckDigit(cleanedCNPJ, 12);
    const checkDigit2 = calcCheckDigit(cleanedCNPJ, 13);
  
    return checkDigit1 === parseInt(cleanedCNPJ[12]) && checkDigit2 === parseInt(cleanedCNPJ[13]);
  };
  
  export { validateCPF, validateCNPJ };
  