import HttpClient from "./HttpClient";

class AuthService {
  async login(credentials) {
    return HttpClient.post("/login", credentials);
  }

  async logout() {
    return HttpClient.post("/logout");
  }
}

export default new AuthService();
