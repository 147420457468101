import axios from "../api/axios";
import AuthService from "../services/AuthService";
import HttpClient from "../services/HttpClient";
import TokenService from "../services/TokenService";
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthentication] = useState(false);
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsString = searchParams.toString();

  useEffect(() => {
    (async () => {
      const access_token = window.localStorage.getItem("access_token");

      if (access_token) {
        try {
          TokenService.setAccessToken(access_token);
          const res = await HttpClient.post("/auth/me");

          setAuthentication(true);
          res.data["access_token"] = access_token;
          setAuth({ ...res.data });

          switch (pathname) {
            case "/login":
            case "/register":
            case "/landingPage":
              navigate(`/dashboard${paramsString ? `?${paramsString}` : ''}`, { replace: true });
              break;
            default:
              navigate(`${pathname || "/dashboard"}${paramsString ? `?${paramsString}` : ''}`, { replace: true });
              break;
          }
        } catch (error) {
          localStorage.clear();
          setAuthentication(false);
          setAuth({});
          navigate("../login", { replace: true });
        }
      }

      setIsLoading(false);
    })();
  }, []);

  const logout = () => {
    localStorage.clear();
    setAuth({});
    navigate("/login");
    navigate(0);
  };

  return (
    <AuthContext.Provider
      value={{
        logout,
        auth,
        setAuth,
        setAuthentication,
        persist,
        setPersist,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
