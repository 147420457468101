import * as Yup from "yup";
// import { useDispatch } from "react-redux";
import Input from "../../../components/Base/Form/InputBox";
import Select from "../../../components/Base/Form/SelectBox";
import { useFormik } from "formik";
import { FormikEffect } from "../../../utils/FormikEffect";
import Modal from "../../../components/Base/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import { extractNumbers } from "../../../utils/helpers";
import { useState } from "react";
import { FormCheck, FormLabel } from "../../../components/Base/Form";
import { validateCNPJ, validateCPF } from "../../../utils/validations";

const apiClient = axios.create({
  baseURL: "http://localhost",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const token =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0L2F1dGgvbG9naW4iLCJpYXQiOjE3MDU5MjgwMTcsImV4cCI6MTcwNTkzMTYxNywibmJmIjoxNzA1OTI4MDE3LCJqdGkiOiJwRUxvRUZiUXB1VnFDQWhTIiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.w1NhDOkpndK1dLpb2DoU--_lSEjsO6iaarvp8LyGya8";

const PhysicalPerson = ({ formik }) => {
  return (
    <div className="flex col-span-12 gap-5 grid grid-cols-12">
      <Input
        name="name"
        label="Nome completo"
        type="tel"
        onChange={formik.handleChange}
        value={formik.values.name}
        error={(formik.touched.name || undefined) && formik.errors.name}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        label="CPF"
        mask={[
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
        name="document"
        placeholder="000.000.000-00"
        value={formik.values.document}
        onChange={formik.handleChange}
        error={(formik.touched.document || undefined) && formik.errors.document}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="email"
        label="E-mail"
        type="email"
        placeholder="usuario@empresa.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={(formik.touched.email || undefined) && formik.errors.email}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="dataOfBirth"
        label="Data de Nascimento"
        max={new Date().toISOString().split("T")[0]}
        type="date"
        value={formik.values.dataOfBirth}
        error={
          (formik.touched.dataOfBirth || undefined) &&
          formik.errors.dataOfBirth
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="tel"
        type="tel"
        label="Telefone"
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        value={formik.values.tel}
        error={(formik.touched.tel || undefined) && formik.errors.tel}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="cell"
        type="tel"
        label="Celular"
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        value={formik.values.cell}
        error={(formik.touched.cell || undefined) && formik.errors.cell}
        containerClassName="col-span-12 sm:col-span-6"
      />
    </div>
  );
};

const LegalPerson = ({ formik }) => {
  return (
    <div className="flex col-span-12 gap-5 grid grid-cols-12">
      <Input
        name="name"
        label="Pessoa de Contato"
        type="tel"
        onChange={formik.handleChange}
        value={formik.values.name}
        error={(formik.touched.name || undefined) && formik.errors.name}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="document"
        label="CNPJ"
        mask={[
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
        placeholder="00.000.000/0000-00"
        value={formik.values.document}
        onChange={formik.handleChange}
        error={(formik.touched.document || undefined) && formik.errors.document}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="fantasy_name"
        label="Nome fantasia"
        value={formik.values.fantasy_name}
        onChange={formik.handleChange}
        error={
          (formik.touched.fantasy_name || undefined) &&
          formik.errors.fantasy_name
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="socialReason"
        label="Razão social"
        value={formik.values.socialReason}
        onChange={formik.handleChange}
        error={
          (formik.touched.socialReason || undefined) &&
          formik.errors.socialReason
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="email"
        label="E-mail"
        type="email"
        placeholder="usuario@empresa.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={(formik.touched.email || undefined) && formik.errors.email}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="tel"
        type="tel"
        label="Telefone Comercial"
        onChange={formik.handleChange}
        value={formik.values.tel}
        error={(formik.touched.tel || undefined) && formik.errors.tel}
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        containerClassName="col-span-12 sm:col-span-3"
      />
      <Input
        name="cell"
        type="tel"
        label="Celular"
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        value={formik.values.cell}
        onChange={formik.handleChange}
        error={(formik.touched.cell || undefined) && formik.errors.cell}
        containerClassName="col-span-12 sm:col-span-3"
      />
    </div>
  );
};

const Address = ({ formik }) => {
  return (
    <div>
      <div className="flex col-span-12 gap-5 grid grid-cols-12">
        <Input
          name="zipCode"
          label="CEP"
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
          placeholder="00000-000"
          value={formik.values.zipCode}
          onChange={formik.handleChange}
          error={(formik.touched.zipCode || undefined) && formik.errors.zipCode}
          containerClassName="col-span-12 sm:col-span-3"
        />
      </div>
      <div className="flex col-span-12 gap-5 grid grid-cols-12">
        <Input
          name="street"
          label="Logradouro"
          value={formik.values.street}
          onChange={formik.handleChange}
          error={(formik.touched.street || undefined) && formik.errors.street}
          containerClassName="col-span-12 sm:col-span-6"
        />
        <Input
          name="complement"
          label="Complemento"
          value={formik.values.complement}
          onChange={formik.handleChange}
          error={
            (formik.touched.complement || undefined) && formik.errors.complement
          }
          containerClassName="col-span-12 sm:col-span-4"
        />
        <Input
          name="number"
          label="Número"
          value={formik.values.number}
          onChange={formik.handleChange}
          error={(formik.touched.number || undefined) && formik.errors.number}
          containerClassName="col-span-12 sm:col-span-2"
        />
      </div>
      <div className="flex col-span-12 gap-5 grid grid-cols-12">
        <Input
          name="neighborhood"
          label="Bairro"
          value={formik.values.neighborhood}
          onChange={formik.handleChange}
          error={
            (formik.touched.name || undefined) && formik.errors.neighborhood
          }
          containerClassName="col-span-12 sm:col-span-6"
        />
        <Select
          label="Cidade"
          name="city"
          containerClassName="col-span-12 sm:col-span-4"
          allowClear
          value={formik.values.city}
          error={(formik.touched.city || undefined) && formik.errors.city}
          onChange={(value) => formik.setFieldValue("city", value)}
          options={[
            {
              value: "Mateus Leme",
              label: "Mateus Leme",
            },
            {
              value: "Betim",
              label: "Betim",
            },
            {
              value: "Contagem",
              label: "Contagem",
            },
            {
              value: "Belo Horizonte",
              label: "Belo Horizonte",
            },
          ]}
        />
        <Select
          label="UF"
          name="uf"
          value={formik.values.uf}
          onChange={(value) => formik.setFieldValue("uf", value)}
          containerClassName="col-span-12 sm:col-span-2"
          error={(formik.touched.uf || undefined) && formik.errors.uf}
          allowClear
          options={[
            { value: "", label: "Selecione uma opção" },
            { label: "AC", value: "AC" },
            { label: "AL", value: "AL" },
            { label: "AP", value: "AP" },
            { label: "AM", value: "AM" },
            { label: "BA", value: "BA" },
            { label: "CE", value: "CE" },
            { label: "DF", value: "DF" },
            { label: "ES", value: "ES" },
            { label: "GO", value: "GO" },
            { label: "MA", value: "MA" },
            { label: "MT", value: "MT" },
            { label: "MS", value: "MS" },
            { label: "MG", value: "MG" },
            { label: "PA", value: "PA" },
            { label: "PB", value: "PB" },
            { label: "PR", value: "PR" },
            { label: "PE", value: "PE" },
            { label: "PI", value: "PI" },
            { label: "RJ", value: "RJ" },
            { label: "RN", value: "RN" },
            { label: "RS", value: "RS" },
            { label: "RO", value: "RO" },
            { label: "RR", value: "RR" },
            { label: "SC", value: "SC" },
            { label: "SP", value: "SP" },
            { label: "SE", value: "SE" },
            { label: "TO", value: "TO" },
          ]}
        />
      </div>
    </div>
  );
};

const CreateCustomerModal = (props) => {
  const { setCustomers, isModalOpen, closeModal } = props;

  const formik = useFormik({
    initialValues: {
      type: "fisica",
      name: "",
      document: "",
      email: "",
      date_of_birth: "",
      cell: "",
      tel: "",
      socialReason: "",
      fantasy_name: "",
      zipCode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      uf: "",
      city: "",
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Tipo de pessoa é obrigatório"),
      name: Yup.string().when("type", (val) => {
        return Yup.string()
          .min(3, "Informe pelo menos 3 caracteres")
          .required(
            val[0] === "fisica"
              ? "Nome é obrigatório"
              : "Pessoa de Contato é obrigatório"
          );
      }),
      document: Yup.string().when("type", (val) => {
        if (val[0] === "fisica") {
          return Yup.string()
            .required("CPF é obrigatório")
            .test("cpf", "CPF inválido", validateCPF);
        }
        return Yup.string()
          .required("CNPJ é obrigatório")
          .test("cnpj", "CNPJ inválido", validateCNPJ);
      }),
      email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
      fantasy_name: Yup.string().when("type", (val) => {
        if (val[0] === "juridica") {
          return Yup.string().required("Nome fantasia é obrigatório");
        }
        return Yup.string();
      }),
    }),

    onSubmit: async (values) => {
      apiClient.defaults.headers.common.Authorization = `Bearer ` + token;

      try {
        const response = await apiClient.post("/customers", {
          name: values.name,
          email: values.email,
          cpf_cnpj: extractNumbers(values.document),
          fantasy_name: values.fantasy_name,
          status: true,
          zipcode: values.zipcode,
          street: values.street,
          number: values.number,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.state,
          complemen: values.complemen,
        });

        setCustomers((oldCustomers) => {
          const customers = [...oldCustomers];
          customers.unshift({
            ...response.data.customer,
          });
          return customers;
        });

        closeModal();
        toast.success("Cliente adicionado com sucesso!");
        formik.resetForm();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const responseData = error.response.data;
            if (responseData && responseData.errors) {
              formik.setErrors(responseData.errors);
            }
          }
        } else {
          toast.error("Error!");
        }
      }
    },
  });

  return (
    <Modal
      open={isModalOpen}
      closeModal={() => {
        formik.resetForm();
        closeModal();
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
        className="grid grid-cols-12 gap-5"
        id="customerForm"
      >
        <FormikEffect formik={formik} />
        <div className="mb-5 col-span-12">
          <FormLabel>Tipo de pessoa</FormLabel>
          <div className="flex flex-col mt-3 sm:flex-row">
            <FormCheck className="mr-5">
              <FormCheck.Input
                id="radio-switch-4"
                type="radio"
                checked={formik.values.type === "fisica"}
                onChange={() => formik.setFieldValue("type", "fisica")}
                name="horizontal_radio_button"
                value="fisica"
              />
              <FormCheck.Label htmlFor="radio-switch-4">
                Pessoa física
              </FormCheck.Label>
            </FormCheck>
            <FormCheck className="mt-2 mr-2 sm:mt-0">
              <FormCheck.Input
                id="radio-switch-5"
                type="radio"
                checked={formik.values.type === "juridica"}
                onChange={() => formik.setFieldValue("type", "juridica")}
                name="horizontal_radio_button"
                value="juridica"
              />
              <FormCheck.Label htmlFor="radio-switch-5">
                Pessoa jurídica
              </FormCheck.Label>
            </FormCheck>
          </div>
        </div>
        {formik.values.type === "fisica" ? (
          <PhysicalPerson formik={formik} />
        ) : (
          <LegalPerson formik={formik} />
        )}
        <p className="text-primary text-base font-semibold leading-[14px] my-2.5 tracking-[-0.32px] col-span-12">
          Endereço
        </p>
        <Address formik={formik} />
      </form>
    </Modal>
  );
};

export default CreateCustomerModal;
