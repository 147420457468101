// import Lucide from "@/components/Base/Lucide";
import { useEffect, useState } from "react";
// import Button from "@/components/Base/Button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils/helpers";
import Header from "../pages/Header";
// import MobileMenu from "@/components/MobileMenu";
import useAuth from "../utils/hooks/useAuth";

const pagesContent = {
  Clientes: {
    title: "Clientes",
    text: "Visualize, filtre e gerencie todos os clientes e leads do seu negócio.",
  },
  Pedidos: {
    title: "Pedidos",
    text: "Visualize, filtre e gerencie todos os pedidos do seu negócio.",
  },
  Faturas: {
    title: "Faturas",
    text: "Visualize, filtre e gerencie todos as faturas do seu negócio.",
  },
  "Produtos-servicos": {
    title: "Lista de Produtos/Serviços",
    text: "Visualize, filtre e gerencie todos os produtos/serviços do seu negócio.",
  },
  "Adicionar-produto-servico": {
    title: "Adicionar Produto/Serviço",
    text: "Crie produtos aqui para comercializar com seus clientes.",
  },
  "Editar-produto-servico": {
    title: "Editar Produto/Serviço",
    text: "Crie produtos aqui para comercializar com seus clientes.",
  },
  "Grupos-produtos-servicos": {
    title: "Criar grupos de Produtos/Serviços",
    text: "Definir descrição",
  },
  "Novo-pedido": {
    title: "Novo pedido",
    text: "Definir descrição",
  },
  Configuracoes: {
    title: "Cupons",
    text: "Definir descrição",
  },
  Tickets: {
    title: "Tickets",
    text: "Visualize, filtre e gerencie todos os tickets do seu negócio.",
  },
  Logs: {
    title: "Logs",
    text: "Visualize detalhadamente tudo que acontece no seu negócio.",
  },
};

const Base = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  const [menuActive, setMenuActive] = useState("Clientes");
  const [content, setContent] = useState({});

  const { auth } = useAuth();

  useEffect(() => {
    const pageKey = capitalizeFirstLetter(
      location.pathname.substring(1).split("/")[0].toLowerCase()
    );
    setMenuActive(pageKey);
    setContent(pagesContent[pageKey]);
  }, [location]);

  return (
    <div className="">
      <Header menuType={auth?.type ? "admin" : "admin"} />

      <section className="bg-[#17191E] min-h-[132px]">
        <div className="container flex items-start justify-between flex-col xl:flex-row h-full px-5 pt-[15px]">
          <div className="flex flex-col h-full mt-[.5rem] ">
            <div className="flex gap-2 items-center">
              <span className="text-[#5E6278] font-inter font-semibold text-[13px] leading-[14px]">
                Clientes
              </span>
              <span className="text-[#5E6278] font-inter font-semibold text-[13px] leading-[14px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M0.893444 9.66664C0.761028 9.66741 0.631381 9.62873 0.521035 9.55553C0.410689 9.48233 0.324641 9.37793 0.273864 9.25563C0.223086 9.13334 0.209878 8.99869 0.235925 8.86886C0.261971 8.73903 0.326091 8.61989 0.420111 8.52664L3.90011 4.99998L0.420111 1.55331C0.357952 1.49115 0.308644 1.41736 0.275004 1.33614C0.241364 1.25493 0.224049 1.16788 0.224049 1.07998C0.224049 0.992072 0.241364 0.905026 0.275004 0.823812C0.308644 0.742597 0.357952 0.668804 0.420111 0.606645C0.48227 0.544486 0.556063 0.495179 0.637278 0.461538C0.718492 0.427898 0.805538 0.410583 0.893444 0.410583C0.98135 0.410583 1.0684 0.427898 1.14961 0.461538C1.23082 0.495179 1.30462 0.544486 1.36678 0.606645L5.33344 4.55998C5.45761 4.68489 5.52731 4.85385 5.52731 5.02998C5.52731 5.2061 5.45761 5.37507 5.33344 5.49998L1.38678 9.44664C1.32445 9.51571 1.24835 9.57096 1.16339 9.60885C1.07843 9.64674 0.986473 9.66642 0.893444 9.66664Z"
                    fill="#5E6278"
                  />
                </svg>
              </span>
              <span className="text-white font-inter text-[13px] font-semibold leading-[14px]">
                Lista de clientes
              </span>
            </div>
          </div>
        </div>
      </section>

      <div className="container px-5">
        <main className="translate-y-[-68px] bg-white h-auto p-5 rounded-[10px] xl:p-[30px] shadow-custom-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Base;
