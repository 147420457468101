import "../../assets/css/themes/icewall/top-nav.css";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  FormattedMenu,
  linkTo,
  nestedMenu,
} from "../Menu/Top";
import Lucide from "../../components/Base/Lucide";
import clsx from "clsx";
import useMenu from "../Menu/Main/top-menu"; // Agora só existe o Menu

const TopBar = () => {
  const Menu = useMenu();
  const navigate = useNavigate();
  const location = useLocation();
  const [formattedMenu, setFormattedMenu] = useState([]);
  

  // Use o Menu diretamente, sem a distinção entre customer e admin
  const topMenu = () => nestedMenu(Menu, location);

  useEffect(() => {
    setFormattedMenu(topMenu());
  }, [location.pathname]);

  return (
    <div
      className={clsx([
        "icewall px-5 sm:px-0 py-5 hidden xl:block relative w-fit pr-0",
      ])}
    >
      <nav className="top-nav relative z-50 -mt-2 md:block xl:-mt-[3px] xl:px-6 xl:pt-[12px] pr-0">
        <ul className="h-[50px] flex flex-norap font-nunito text-base w-fit">
          {formattedMenu.map(
            (menu, menuKey) =>
              menu !== "divider" && (
                <li key={menuKey}>
                  <a
                    href={menu.subMenu ? "#" : menu.pathname}
                    className={clsx([
                      menu.active ? "top-menu top-menu--active" : "top-menu",
                    ])}
                    onClick={(event) => {
                      event.preventDefault();
                      linkTo(menu, navigate);
                    }}
                  >
                    <div className="top-menu__icon">
                      <Lucide icon={menu.icon} />
                    </div>
                    <div className="top-menu__title">
                      {menu.title}
                    </div>
                  </a>
                  {menu.subMenu && (
                    <ul>
                      {menu.subMenu.map((subMenu, subMenuKey) => (
                        <li key={subMenuKey}>
                          <a
                            href={subMenu.subMenu ? "#" : subMenu.pathname}
                            className="top-menu"
                            onClick={(event) => {
                              event.preventDefault();
                              linkTo(subMenu, navigate);
                            }}
                          >
                            <div className="top-menu__icon">
                              <Lucide icon={subMenu.icon} />
                            </div>
                            <div className="top-menu__title">
                              {subMenu.title}
                              {subMenu.subMenu && (
                                <Lucide
                                  className="top-menu__sub-icon"
                                  icon="ChevronDown"
                                />
                              )}
                            </div>
                          </a>
                          {subMenu.subMenu && (
                            <ul
                              className={clsx({
                                "side-menu__sub-open": subMenu.activeDropdown,
                              })}
                            >
                              {subMenu.subMenu.map(
                                (lastSubMenu, lastSubMenuKey) => (
                                  <li key={lastSubMenuKey}>
                                    <a
                                      href={
                                        lastSubMenu.subMenu
                                          ? "#"
                                          : lastSubMenu.pathname
                                      }
                                      className="top-menu"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        linkTo(lastSubMenu, navigate);
                                      }}
                                    >
                                      <div className="top-menu__icon">
                                        <Lucide icon={lastSubMenu.icon} />
                                      </div>
                                      <div className="top-menu__title">
                                        {lastSubMenu.title}
                                      </div>
                                    </a>
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
          )}
        </ul>
      </nav>
    </div>
  );
};

export default TopBar;
