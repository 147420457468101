import { twMerge } from "tailwind-merge";
import { createContext, useContext } from "react";

// Criar contextos
const tableContext = createContext({
  dark: false,
  bordered: false,
  hover: false,
  striped: false,
  sm: false,
});

function Table({
  className,
  dark = false,
  bordered = false,
  hover = false,
  striped = false,
  sm = false,
  ...props
}) {
  return (
    <tableContext.Provider
      value={{
        dark,
        bordered,
        hover,
        striped,
        sm,
      }}
    >
      <table
        className={twMerge([
          "w-full text-left",
          dark && "bg-dark text-white dark:bg-black/30",
          className,
        ])}
        {...props}
      >
        {props.children}
      </table>
    </tableContext.Provider>
  );
}

const theadContext = createContext({
  variant: "default",
});

// Componentes de função com letra maiúscula
Table.Thead = function Thead({ className, variant = "default", ...props }) {
  return (
    <theadContext.Provider value={{ variant }}>
      <thead
        className={twMerge([
          variant === "light" && "bg-slate-200/60 dark:bg-slate-200",
          variant === "dark" && "bg-dark text-white dark:bg-black/30",
          className,
        ])}
        {...props}
      >
        {props.children}
      </thead>
    </theadContext.Provider>
  );
};

Table.Tbody = function Tbody({ className, ...props }) {
  return <tbody className={className}>{props.children}</tbody>;
};

Table.Tr = function Tr({ className, ...props }) {
  const table = useContext(tableContext);
  return (
    <tr
      className={twMerge([
        table.hover &&
          "[&:hover_td]:bg-primary/5 [&:hover_td]:cursor-pointer [&:hover_td]:dark:bg-darkmode-300 [&:hover_td]:dark:bg-opacity-50",
        table.striped &&
          "[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50",
        className,
      ])}
      {...props}
    >
      {props.children}
    </tr>
  );
};

Table.Th = function Th({ className, ...props }) {
  const table = useContext(tableContext);
  const thead = useContext(theadContext);
  return (
    <th
      className={twMerge([
        "font-medium px-5 py-3",
        thead.variant === "light" && "text-slate-700",
        table.sm && "px-4 py-2",
        className,
        "py-0",
      ])}
      {...props}
    >
      {props.children}
    </th>
  );
};

Table.Td = function Td({ className, ...props }) {
  const table = useContext(tableContext);
  return (
    <td
      className={twMerge([
        "px-5 py-3",
        table.sm && "px-4 py-2",
        className,
      ])}
      {...props}
    >
      {props.children}
    </td>
  );
};

export default Table;
