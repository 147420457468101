import React, { useContext } from 'react';
import { formInlineContext } from '../FormInline';
import { twMerge } from 'tailwind-merge';

function FormLabel(props) {
  const formInline = useContext(formInlineContext);
  return (
    <label
      {...props}
      className={twMerge([
        'inline-block mb-2 text-[#78829D] font-nunito-sans text-[14px] font-semibold leading-[14px] tracking-[-0.28px]',
        formInline && 'mb-2 sm:mb-0 sm:mr-5 sm:text-right',
        props.className,
      ])}
    >
      {props.children}
    </label>
  );
}

export default FormLabel;
